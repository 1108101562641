import React from 'react'
import { RecoilRoot } from 'recoil'

interface wrapperProps {
  element: any
  props: any
}

export const wrapRootElement = ({ element, props }: wrapperProps): any => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>
}
